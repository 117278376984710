<template>
  <div>
    <Breadcrumbs title="Meetings" main="Zoom"/>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">

              <b-row>
                <b-col cols="12" lg="6" xl="3">
                  <b-form-group label="Institute">
                    <b-form-select @change="fetchTeachers" v-model="formSearch.institute_id" :options="institutes" value-field="id" text-field="text" class="btn-square border"></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col cols="12" lg="6" xl="3">
                  <b-form-group label="Teacher">
                    <b-form-select @change="fetchClasses" v-model="formSearch.teacher_id" :options="teachers" value-field="id" text-field="text" class="btn-square border"></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col cols="12" xl="6">
                  <b-form-group label="Class">
                    <b-form-select @change="clearResult()" v-model="formSearch.class_detail_id" :options="class_details" value-field="id" text-field="text" class="btn-square border" required></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col cols="12" lg="6" xl="3">
                  <b-form-group label="Start Date *">
                    <b-form-datepicker @input="clearResult()" reset-button close-button v-model="formSearch.start_date"></b-form-datepicker>
                  </b-form-group>
                </b-col>
                <b-col cols="12" lg="6" xl="3">
                  <b-form-group label="End Date *">
                    <b-form-datepicker @input="clearResult()" reset-button close-button v-model="formSearch.end_date" class="btn-square border"></b-form-datepicker>
                  </b-form-group>
                </b-col>
                <b-col cols="12" xl="3">
                  <div class="btn btn-group mt-4 text-left pl-0">
                    <b-button @click="fetchData" class="btn btn-square border mr-1" variant="secondary">Search</b-button>
                    <b-button v-show="checkUserPermissions(user,['create_zoom_meeting'])" @click="showCreateModal" class="btn btn-square border" variant="success">New Meeting</b-button>
                  </div>
                </b-col>
              </b-row>

              <b-row v-show="false">
                <b-col cols="12" class="m-t-10">
                  <b-input-group>
                    <b-form-input v-model="filter" placeholder="Type to Search" class="border btn-square" autofocus></b-form-input>
                    <b-input-group-append>
                      <b-button :disabled="!filter" @click="filter = ''" class="btn btn-square border" variant="primary">Clear</b-button>
                      <b-button v-show="checkUserPermissions(user,['create_zoom_meeting'])" @click="showCreateModal" class="btn btn-square border" variant="success">New Meeting</b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-col>
              </b-row>

              <div class="table-responsive datatable-vue">
                <b-table
                    ref="table"
                    show-empty
                    selected-variant="success"
                    :items="tableRecords"
                    :fields="tableFields"
                    :filter="filter"
                    :current-page="1"
                    :per-page="perPage"
                    @filtered="onFiltered"
                    @row-selected="rowSelected"
                >
                  <template #cell(action)="row" class="text-right">
                    <b-button v-show="checkUserPermissions(user,['update_zoom_configs'])" class="btn-square mr-1 ml-1" variant="success" @click="showEditModal(row.index, row.item, $event.target)">Edit</b-button>
                  </template>
                </b-table>
              </div>


              <b-row >
                <b-col cols="4">
                  Records: <strong>{{ totalRows }}</strong>
                </b-col>
                <b-col cols="8" class="my-1" v-if="totalRows">
                  <b-pagination
                      align="right"
                      class="my-0"
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                      pills
                  ></b-pagination>
                </b-col>
              </b-row>



              <!-- Begin Delete Modal -->
              <b-modal id="delete_modal" title="Delete" cancel-variant="default" ok-variant="danger" ok-title="Confirm" @hide="clearFormModals" @ok="submitDeleteModal">
                <h6 class="m-b-10">Please confirm removal of following record:</h6>
                <strong v-text="selected.name"></strong>
              </b-modal>
              <!-- End Delete Modal -->

              <!-- Begin Create Modal -->
              <b-modal id="form_modal" size="xl" :title="form_modal_title" cancel-variant="default" ok-variant="primary" :ok-title="form_modal_ok_title" @hide="clearFormModals" @ok="submitFormModal">
                <div class="row">
                  <div class="col-12 col-lg-6">
<!--                    <p>General</p>-->
                    <b-form-group label="Id" v-if="formModal.id">
                      <b-input v-if="form_modal_id === 'form_modal_create'" v-model="formModal.id"></b-input>
                      <b-input v-else v-model="formModal.id" readonly></b-input>
                    </b-form-group>
                    <b-form-group label="Zoom Meeting ID" v-if="formModal.id">
                      <b-input :disabled="!formModal.id" type="text" class="btn-square" v-model="formModal.zoom_meeting_id"></b-input>
                    </b-form-group>
                    <b-form-group label="Zoom Account *">
                      <b-form-select v-model="formModal.zoom_account_config_id" :options="configurations" value-field="id" text-field ="text" class="btn-square border"></b-form-select>
                    </b-form-group>
                    <b-form-group label="Meeting Topic *">
                      <b-input :disabled="!formModal.zoom_account_config_id" type="text" class="btn-square" v-model="formModal.topic" required></b-input>
                    </b-form-group>
                    <b-form-group label="Password *">
                      <b-input :disabled="!formModal.topic" type="text" class="btn-square" v-model="formModal.password" required></b-input>
                    </b-form-group>
                    <b-form-group label="Date *">
                      <b-form-datepicker :min="min_date" :disabled="!formModal.password" v-model="formModal.start_date" required></b-form-datepicker>
                    </b-form-group>
                    <div class="row">
                      <div class="col-6">
                        <b-form-group label="Start Time *">
                          <b-form-timepicker :disabled="!formModal.password" v-model="formModal.start_time" required></b-form-timepicker>
                        </b-form-group>
                      </div>
                      <div class="col-6">
                        <b-form-group label="Duration *">
                          <b-input :disabled="!formModal.password" type="number" class="btn-square" v-model="formModal.duration" required></b-input>
                        </b-form-group>
                      </div>
                    </div>
                    <div class="row" v-show="selectedClasses.length">
                      <div class="col-12">
                        <label>Classes *</label>
                        <table class="table table-condensed table-bordered">
                          <tr v-for="cd in selectedClasses" :key="cd.id">
                            <td>{{cd.institute_name}} - {{cd.text}}</td>
                            <td><b-button variant="danger" v-on:click="() => revokeClassDetail(cd.id)">Remove</b-button></td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
<!--                  <div class="col-12 col-lg-6">-->
<!--                    <p>Settings</p>-->
<!--                    <b-form-group>-->
<!--                      <b-checkbox class="btn-square" v-model="formModal.settings.host_video"></b-checkbox> Host Video-->
<!--                    </b-form-group>-->
<!--                  </div>-->
                  <div class="col-12 col-lg-6">

                    <b-form-group label="Institute *">
                      <b-form-select @change="instituteChanged" v-model="addMeetingForm.institute_id" :options="institutes" value-field="id" text-field="text" class="btn-square border">
                        <template #first>
                          <b-form-select-option :value="null" disabled>-- Select an option --</b-form-select-option>
                        </template>
                      </b-form-select>
                    </b-form-group>

                    <div class="form-group">
                      <label class="col-form-label">Subject</label>
                      <b-form-select @change="loadTeachers" v-model="addMeetingForm.subject_id" :options="subjects" value-field="id" text-field="text" class="border" required>
                        <template #first>
                          <option :value="null" selected>-- Please select an option --</option>
                        </template>
                      </b-form-select>
                    </div>

                    <div class="form-group">
                      <label class="col-form-label">Teacher</label>
                      <b-form-select :disabled="!teachers.length" @change="loadClassDetails" class="form-control border" v-model="addMeetingForm.teacher_id">
                        <template #first>
                          <b-form-select-option :value="null" disabled>-- Please select an option --</b-form-select-option>
                        </template>
                        <b-form-select-option v-for="(teacher, index) in teachers" :key="index" :value="teacher.id" v-text="teacher.text"></b-form-select-option>
                      </b-form-select>
                    </div>

                    <div class="form-group">
                      <label class="col-form-label">Class</label>
                      <b-form-select :disabled="!class_details.length" class="form-control border" v-model="addMeetingForm.class_detail_id" :options="class_details" value-field="id" text-field="text">
                        <template #first>
                          <b-form-select-option :value="null" disabled>-- Please select an option --</b-form-select-option>
                        </template>
                      </b-form-select>
                    </div>

                    <div class="form-group">
                      <b-button class="btn-square mr-1 ml-1" variant="success" @click="assignClassDetail">Assign Class</b-button>
                    </div>

                  </div>
                </div>
              </b-modal>
              <!-- End Create Modal -->

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {checkUserPermissions} from '@/plugins/functions'
import {mapGetters, mapState} from 'vuex'
import CRUD_API from "@/services/crud";
import API from "@/services/api";

export default {
  name: "meeting_list",
  computed: {
    ...mapState({ user: state => JSON.parse(state.auth.user) }),
    ...mapGetters({
      institutes: 'select/institutes',
      streams: 'select/streams',
      configurations: 'select/zoom_configurations',
    })
  },
  created() {
    this.$store.dispatch('select/fetchRecords', { id: 'institutes' })
    this.$store.dispatch('select/fetchRecords', { id: 'streams' })
    this.$store.dispatch('select/fetchRecords', { id: 'zoom-configurations' })
  },
  data() {
    return {
      filter: '',
      totalRows: 0,
      perPage: 5,
      currentPage: 1,
      pageOptions: [5,10,25,50,100],
      tableRecords: [],
      selected: [],

      subjects: [],
      teachers: [],
      classrooms: [],
      classTypes: [],
      class_details: [],
      api_base: '/api/backend/zoom-v2/meeting-list',
      tableFields: [
        // { key: 'id', label: 'ID', sortable: false },
        { key: 'topic', label: 'Topic', sortable: true },
        { key: 'zoom_account_config.name', label: 'Account Name', sortable: true },
        { key: 'start_time', label: 'Start Time', sortable: true },
        { key: 'password', label: 'Password', sortable: true },
        { key: 'action', label: 'Action', sortable: false, class: 'text-right text-capitalize' }
      ],

      min_date: null,
      addMeetingForm: {
        institute_id: null,
        stream_id: null,
        subject_id: null,
        teacher_id: null,
        class_detail_id: null
      },


      formSearch: {
        start_date:'',
        end_date: '',
        institute_id: null,
        teacher_id: null,
        subject_id: null,
        class_detail_id: null,
        year: new Date().getFullYear()
      },


      // Form Modal
      form_modal_id: '',
      form_modal_title: '',
      form_modal_ok_variant: 'primary',
      form_modal_ok_title: '',
      selectedClasses: [],
      formModal: {
        id: null,
        index: null,
        zoom_account_config_id: null,
        zoom_meeting_id: null,
        topic: null,
        start_date: null,
        start_time: null,
        password: null,
        duration: 0,
        // recurrence: {},
        settings: {
          host_video: null,//boolean
          participant_video: null,//boolean
          cn_meeting: null,//boolean
          in_meeting: null,//boolean
          join_before_host: null,//boolean
          mute_upon_entry: null,//boolean
          watermark: null,//boolean
          use_pmi: null,//boolean
          approval_type: null,// integer
          registration_type: null,// integer
          audio: null, // string
          auto_recording: null, // string
          enforce_login: null,//boolean
          enforce_login_domains: null, // string
          alternative_hosts: null, // string
          registrants_email_notification: null,//boolean
        },
      },
    }
  },
  watch: {
    currentPage() {
      this.fetchData()
    },
    perPage() {
      this.currentPage = 1
      this.fetchData()
    },
    filter() {
      this.currentPage = 1
      this.fetchData()
    },
  },
  mounted() {
    this.fetchData()
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    this.min_date = new Date(today)
  },
  methods: {
    checkUserPermissions,
    onFiltered(filteredItems) {
      this.currentPage = 1
      this.items.count = filteredItems.length
    },
    rowSelected(item) {
      this.selected = item
    },
    clearSearch() {
      this.filter = ''
      this.currentPage = 1
      this.class_details = []
      this.fetchData()
    },
    handlePageChange(value) {
      //alert(value)
      this.perPage = value
      this.fetchData()
    },
    handlePageSizeChange(value) {
      this.currentPage = value
      this.fetchData()
    },
    async fetchData() {
      let params = {
        ...this.formSearch,
        per_page: this.perPage,
        page: this.currentPage,
        filter: this.filter,
      }
      await CRUD_API.index( this.api_base, {
        url: this.api_base,
        params
      }).then(function(resp) {
        this.tableRecords = []
        //this.currentPage = resp.data.data.current_page
        //this.perPage = resp.data.data.per_page
        this.totalRows = resp.data.data.total
        this.tableRecords = resp.data.data.data
      }.bind(this)).catch((err) => {
        console.log(err)
        this.$toasted.error("Error occurred while retrieving data")
      }).finally(() => {
        //this.$refs.table.refresh()
      })
    },


    async fetchTeachers() {
      this.clearResult()
      this.teachers = []
      await API.select('teachers', {
        institute_id: this.formSearch.institute_id
      }).then((result) => {
        this.teachers = result.data.data
      }).catch((error) => {
        console.log(error)
      }).finally(() => {
        this.teachers.unshift({
          id: null, text: "All Teachers"
        })
        this.fetchClasses()
      })
    },
    async fetchClasses() {
      this.clearResult()
      this.class_details = []
      this.formSearch.class_detail_id = null
      await API.select('classes', {
        institute_id: this.formSearch.institute_id,
        teacher_id: this.formSearch.teacher_id,
      }).then(result => {
        this.class_details = result.data.data
      }).catch(error => {
        console.log(error)
      }).finally(() => {
        this.class_details.unshift({
          id: null, text: "All Classes"
        })
      })
    },
    clearResult() {},
    loadTeachers() {
      this.teachers = []
      this.addMeetingForm.teacher_id = null
      this.class_details = []
      this.addMeetingForm.class_detail_id = null
      CRUD_API.index('/api/backend/select/teachers', {
        params: {
          institute_id: this.addMeetingForm.institute_id,
          subject_id: this.addMeetingForm.subject_id
        }
      }).then((resp) => {
        this.teachers = resp.data.data
      })
    },
    loadClassDetails() {
      this.class_details = []
      this.addMeetingForm.class_detail_id = null
      CRUD_API.index('/api/backend/select/classes', {
        params: {
          institute_id: this.addMeetingForm.institute_id,
          subject_id: this.addMeetingForm.subject_id,
          teacher_id: this.addMeetingForm.teacher_id
        }
      }).then((resp) => {
        this.class_details = resp.data.data
      })
    },
    instituteChanged(){
      // this.formModal.classroom_id = null
      // this.classrooms = []
      this.addMeetingForm.stream_id = null
      this.subjects = [];
      this.addMeetingForm.subject_id = null
      this.teachers = [];
      this.addMeetingForm.teacher_id = null

      CRUD_API.index('/api/backend/select/subjects', {
        params: {
          institute_id: this.addMeetingForm.institute_id
        }
      }).then((resp) => {
        this.subjects = resp.data.data
      })
    },
    streamChanged(){
      this.subjects = [];
      this.addMeetingForm.subject_id = null
      this.teachers = [];
      this.addMeetingForm.teacher_id = null
      CRUD_API.index('/api/backend/select/subjects', {
        params: { stream_id: this.stream_id }
      }).then((resp) => {
        this.subjects = resp.data.data
      })
    },
    subjectChanged() {
      this.teachers = [];
      this.addMeetingForm.teacher_id = null;
      CRUD_API.index('/api/backend/select/teachers', {
        params: { subject_id: this.addMeetingForm.subject_id }
      }).then((resp) => {
        this.teachers = resp.data.data
      })
    },


    assignClassDetail() {
      let cd = this.class_details.find(cls => cls.id===this.addMeetingForm.class_detail_id);
      if(!this.selectedClasses.find(cdf => cdf.id === cd.id)) {
        this.selectedClasses.push({...cd, institute_name: this.institutes.find(inst => inst.id === this.addMeetingForm.institute_id).text})
      }
    },
    revokeClassDetail(class_detail_id){
      console.log(class_detail_id)
      this.selectedClasses = this.selectedClasses.filter(cd => cd.id !== class_detail_id)
    },
    clearFormModals() { this.selected = []; },
    showCreateModal(button) {
      this.formModal.index = null
      this.formModal.id = null
      this.formModal.zoom_account_config_id = null
      this.formModal.zoom_meeting_id = null
      this.formModal.topic = null
      this.formModal.start_date = null
      this.formModal.start_time = null
      this.formModal.duration = 0
      this.formModal.settings.host_video = true
      this.formModal.class_details = []
      this.form_modal_title = "New Zoom Meeting"
      this.form_modal_ok_title = 'Submit'
      this.$root.$emit('bv::show::modal', 'form_modal', button)
    },
    submitFormModal() {
      if (this.formModal.id) {
        this.submitEditModal()
      } else {
        this.submitCreateModal()
      }
    },
    submitCreateModal() {
      if(this.formModal.zoom_account_config_id==null ||
          this.formModal.topic.length < 3 ||
          this.formModal.start_date.length < 3 ||
          this.formModal.start_time.length < 3 ||
          this.formModal.password.length < 3 ||
          this.formModal.duration < 1
      ) {
        this.$toasted.error("Please fill the required fields")
        return;
      }
      CRUD_API.create(this.api_base, {
        ...this.formModal,
        class_details: this.selectedClasses.map(cd => cd.id)
      }).then((resp) => {
        console.log(resp)
        this.$toasted.success("Meeting added successfully")
      }).catch((err) => {
        console.log(err)
        this.$toasted.error("Error occurred while saving data")
      }).finally(() => {
        this.fetchData()
      })
    },
    showEditModal(index, item, button) {
      this.formModal.id = item.id
      this.formModal.zoom_account_config_id = item.zoom_account_config_id
      this.formModal.zoom_meeting_id = item.zoom_meeting_id
      this.formModal.topic = item.topic
      this.formModal.password = item.password
      this.formModal.start_date = item.start_date
      this.formModal.start_time = item.start_time.split(' ')[1]
      this.formModal.duration = item.duration
      this.selectedClasses = item.class_details.map(cd => {
        return {
          id: cd.id,
          text: cd.name
        }
      })
      this.form_modal_title = "Edit Zoom Meeting"
      this.form_modal_ok_title = 'Update'
      this.$root.$emit('bv::show::modal', 'form_modal', button)
    },
    submitEditModal() {
      if(this.formModal.zoom_account_config_id==null ||
          this.formModal.topic.length < 3 ||
          this.formModal.start_date.length < 3 ||
          this.formModal.start_time.length < 3 ||
          this.formModal.password.length < 3 ||
          this.formModal.duration < 1
      ) {
        this.$toasted.error("Please fill the required fields")
        return;
      }
      CRUD_API.update(this.api_base, this.formModal.id, {
        ...this.formModal,
        class_details: this.selectedClasses.map(cd => cd.id)
      }).then(() => {
        this.$toasted.success("Meeting updated successfully");
      })
      .catch((err) => {
        this.$toasted.error(err.response.data.message)
      })
      .finally(() => {
        this.fetchData()
      })
    },
    submitDeleteModal(){},
  }
}
</script>

<style scoped>

</style>
